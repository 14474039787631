import Hamburger from './navigation-hamburger';
import Share from './share';

export default new function common($) {
  this.init = () => {
    this.modifyDOM();
    this.addEvents();
    Hamburger();
    Share();
  };

  this.modifyDOM = () => {
  };

  this.addEvents = () => {
    $(window).on('scroll', this.eventHandlers.onScroll);
    $('.site-footer__scroll-top').on('click', this.eventHandlers.onScrollTop);
    $('.component-infobox__toggle').on('click', this.eventHandlers.onToggleSideWidget);
  };

  this.eventHandlers = {
    onScroll: function() {
      $('html').addClass('has-scrolled');
    },
    onScrollTop: function() {
      $('html, body').animate({ scrollTop: 0 }, 'slow');
    },
    onToggleSideWidget: function() {
      $('.component-infobox--side .component-infobox__content').addClass('collapsed');
      $(this).addClass('hidden');
    }
  };

  this.helpers = {

  };

  return this.init;
}(jQuery);

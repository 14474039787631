export default new function hamburger($) {
  let $html;
  let $siteNav;
  let $siteNavToggle;
  let $siteSubNavToggle;
  let $backdrop;

  this.init = () => {
    this.setVars();
    this.modifyDOM();
    this.addEvents();
  };

  this.setVars = () => {
    $html = $('html');
    $siteNav = $('.site-navigation');
    $siteNavToggle = $('.site-navigation-toggle');
    $backdrop = $('.backdrop');
  };

  this.modifyDOM = () => {
  };

  this.addEvents = () => {
    $siteNavToggle.on('click', this.eventHandlers.showMobileMenu);
  };

  this.eventHandlers = {
    showMobileMenu() {
      if ($siteNavToggle.hasClass('active')) {
        $siteNavToggle.removeClass('active');
        $siteNav.removeClass('site-navigation--collapsed');
        $html.removeClass('site-navigation-open');
        $backdrop.removeClass('backdrop--visible');
        $backdrop.addClass('backdrop--fade-out');

        setTimeout(() => {
          $backdrop.removeClass('backdrop--fade-out');
        }, 500);
      } else {
        $siteNavToggle.addClass('active');
        $siteNav.addClass('site-navigation--collapsed');
        $html.addClass('site-navigation-open');
        $backdrop.addClass('backdrop--visible');
        $backdrop.removeClass('backdrop--fade-out');
      }
    }
  };

  this.helpers = {
  };

  return this.init;
}(jQuery);

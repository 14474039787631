export default (function($) {
  const currentUrl = window.location.origin + window.location.pathname;

  this.init = () => {
    this.modifyDOM();
    this.addEvents();
  };


  this.modifyDOM = () => {
  };

  this.addEvents = () => {
    $('body').on('click', '.entry__meta__share__icon--facebook', this.eventHandlers.onFacebookClick).
      on('click', '.entry__meta__share__icon--twitter', this.eventHandlers.onTwitterClick).
      on('click', '.entry__meta__share__icon--linkedin', this.eventHandlers.onLinkedInClick);
  };

  this.eventHandlers = {
    onFacebookClick(e) {
      e.preventDefault();
      window.open('https://www.facebook.com/sharer/sharer.php?u=' + currentUrl, 'Facebook', 'width=600, height=400, scrollbars=no');
    },

    onTwitterClick(e) {
      e.preventDefault();
      window.open('https://twitter.com/intent/tweet?text=&url=' + currentUrl, 'Twitter', 'height=250,width=600');
    },

    onLinkedInClick(e) {
      e.preventDefault();
      const href = 'http://www.linkedin.com/shareArticle?mini=true&url=' + currentUrl + '&title=' + document.title;
      window.open(href, 'LinkedIn', 'width=400,height=500');
    },
  };

  this.helpers =  {

  };

  return this.init;
}).call({}, jQuery);
